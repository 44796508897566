@import "/styles/mixins.scss";

.footer {
  display: flex;
  flex-direction: column;

  &__main {
    width: 100%;
    background: #f2f2f2;

    &__content {
      max-width: 1408px;
      margin: 0 auto;
      padding: 48px 0;

      display: flex;
      align-items: flex-start;
      gap: 48px;

      @include respond-mobile {
        max-width: unset;
        margin: 0;
        flex-direction: column;
        padding: 32px 20px;
        gap: 24px;
      }
    }

    &__col {
      display: flex;
      flex-direction: column;

      p {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5em;
        color: #737373;

        &:first-child {
          font-weight: 600;
          margin-bottom: 12px;
          color: #000000;
        }
      }

      a {
        text-decoration: none;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5em;
        color: #737373;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__rules {
      flex: 1;
    }

    &__social {
      display: flex;
      gap: 12px;
    }
  }

  &__brand {
    width: 100%;
    padding: 24px 0;
    background: #333333;
    color: #a0a0a0;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2em;
  }
}
