.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.children {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
