@import "./variables.scss";

@mixin respond-medium {
  @media (max-width: $medium-breakproint) {
    @content;
  }
}

@mixin respond-small {
  @media (max-width: $small-breakproint) {
    @content;
  }
}

@mixin respond-mobile {
  @media (max-width: $mobile-breakproint) {
    @content;
  }
} ;
