@import "/styles/mixins.scss";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  padding: 64px 48px;
  border: 1px solid #e5e5e5;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.05);
  border-radius: 16px;

  display: flex;
  flex-direction: column;

  color: #333333;
  width: 592px;

  @include respond-mobile {
    border: none;
    box-shadow: none;
    padding: 0;
    width: 100%;
  }
}

.caption {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.25em;
  margin-bottom: 16px;

  @include respond-mobile {
    font-size: 24px;
    line-height: 1.33em;
  }
}

.tip {
  font-size: 16px;
  line-height: 1.5em;
  color: #828282;
  margin-bottom: 24px;
}

.label {
  font-size: 18px;
  line-height: 1.33em;
  margin-bottom: 12px;
}

.textfield {
  margin-bottom: 24px;
}

.submit {
  padding: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.33em;
  border-radius: 12px;
  text-transform: capitalize;
  background: #27ae60;
  color: #ffffff;
  margin-bottom: 32px;

  @include respond-mobile {
    margin-bottom: 24px;
    padding: 12px;
  }

  &:hover {
    background: #27ae60;
    color: #ffffff;
  }

  &_disabled {
    background: #f2f2f2;
    color: #828282;
  }
}

.helper {
  text-align: center;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;

  a {
    color: #27ae60;
    text-decoration: none;
  }
}
