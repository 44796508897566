@import "/styles/mixins.scss";

.header {
  height: 80px;
  border-bottom: 1px solid #e5e5e5;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1000;

  @include respond-mobile {
    height: 60px;
  }

  &__content {
    max-width: 1408px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    &_wide {
      max-width: unset;
      margin: 0;
    }

    @include respond-mobile {
      max-width: unset;
      margin: 0;
      padding: 12px 16px;
    }

    &__links {
      display: flex;
      align-items: center;

      &__logo {
        margin-right: 32px;

        &_with_paddings {
          margin-right: 0;
          padding: 0 32px;
        }

        @include respond-mobile {
          margin-right: 0;
        }
      }

      &__item {
        display: block;
        height: 100%;
        padding: 24px 32px;
        text-decoration: none;
        color: #333333;
        font-size: 18px;
        line-height: 1.78em;

        @include respond-mobile {
          display: none;
        }

        &:hover {
          background: #fbfbfb;
          border-bottom: 1px solid #27ae60;
        }
      }
    }

    &__course_controls {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 32px;
      border-left: 1px solid #e5e5e5;
      border-right: 1px solid #e5e5e5;

      &__course_name {
        font-weight: 500;
        font-size: 20px;
        line-height: 1.2;
        color: inherit;
        text-decoration: none;
      }

      > div {
        display: flex;
        align-items: center;
        gap: 32px;
      }

      &__button {
        padding: 12px 16px;
        text-transform: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: #828282;
        background: #fbfbfb;
        border: 1px solid #e5e5e5;
        border-radius: 8px;

        &:hover {
          color: #828282;
          background: #fbfbfb;
        }

        &_primary {
          border: none;
          color: #ffffff;
          background: #27ae60;

          &:hover {
            color: #ffffff;
            background: #27ae60;
          }
        }
      }
    }

    &__controls {
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 20px 32px;

      &_with_border {
        border-right: 1px solid #e5e5e5;
      }

      @include respond-mobile {
        gap: 0;
      }

      &__button {
        background-color: transparent;
        color: #828282;
        text-transform: capitalize;
        font-size: 14px;
        font-weight: 700;
        padding: 0;

        &:hover {
          background-color: transparent;
          color: #828282;
        }
      }
    }
  }

  &__dummy {
    width: 226px;
    height: 100%;
  }
}
